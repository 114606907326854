import React, { useEffect, useState, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { selectAuthState } from "@/store/features/authSlice"
import { selectCartState, addToCart, setCart } from "@/store/features/cartSlice"
import {
  showPreviewCart,
  setConsolidatedZipCodes,
  selectGenericState,
} from "@/store/features/genericSlice"
import { addToCartItem } from "@/utils/addTocart"
import UpSellInstallServicesCarousalSlick from "@/components/Shared/UpsellInstallServices/InstallServiceCarousalSlick"
import {
  getUserPersona,
  calculateCartData,
  sanitizeDOMString,
  getUpSellAuthData,
  getProcessedProductInfoBasedOnLineItems,
} from "@/utils/helper"
import {
  getProductDataFromSkuIds,
  getZipCodes,
} from "@/components/Shared/UpsellInstallServices/api"
import { getProcessedInstallServiceData } from "@/components/Shared/UpsellInstallServices/utils"
import {
  locationState,
  setUtilityLocation,
} from "@/store/features/locationSlice"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import Input from "@/components/core/Input/Input"
import styles from "@/components/Shared/UpsellInstallServices/index.module.scss"
import { useTranslation } from "next-i18next"
import { getTranslations } from "@/components/Shared/UpsellInstallServices/localization"
import {
  addAnalyticsForheader,
  addAnalyticsForFreeServices,
} from "@/components/Shared/UpsellInstallServices/analytics"

import { WAIT_TIME_FOR_LEAR_MORE } from "@/components/Shared/UpsellInstallServices/analytics/constant"
import ProductCardForUpsell from "@/components/Shared/UpsellInstallServices/ProductCardForUpsell"

const UpSellInstallServices = ({
  authData = {},
  isRequireToLookupFullData = false,
  upSellProductData = [],
  occupyFullWidth = false,
}) => {
  const learnMoreRef = useRef(null)
  const slickRef = useRef(null)
  const { cart } = useSelector(selectCartState)
  const { isAuth, user: { firstName = "" } = {} } = useSelector(selectAuthState)
  const { utilityLocation = "" } = useSelector(locationState)
  const { consolidatedZipCodes = [] } = useSelector(selectGenericState)
  const { t } = useTranslation("common")
  const staticText = getTranslations(t)
  const { search = "Search" } = staticText
  const dispatch = useDispatch()
  const [upSellData, setUpSellData] = useState([])
  const [showUpSellModule, setShowUpSellModule] = useState(false)
  const [zipCodeVal, setZipCodeVal] = useState(utilityLocation)
  const [showInput, setShowInput] = useState(utilityLocation ? false : true)
  const [isServiceAvailable, setIsServiceAvailable] = useState(true)

  useEffect(() => {
    if (utilityLocation) setZipCodeVal(utilityLocation)
  }, [utilityLocation])

  const upsellHeaderCLickHandler = e => {
    e.preventDefault()
    const { target: { href = "", textContent = "" } = {} } = e
    addAnalyticsForFreeServices(textContent.toLowerCase(), href)
    window.open(href)
  }

  useEffect(() => {
    let upSellHeaderElement = null
    setTimeout(() => {
      if (learnMoreRef?.current) {
        upSellHeaderElement = learnMoreRef?.current?.querySelector("a")
        upSellHeaderElement?.addEventListener("click", upsellHeaderCLickHandler)
      }
    }, WAIT_TIME_FOR_LEAR_MORE)
    if (!zipCodeVal) setZipCodeVal(localStorage?.getItem("currentZipCode"))
    return () => {
      upSellHeaderElement?.removeEventListener(
        "click",
        upsellHeaderCLickHandler
      )
    }
  }, [])

  const {
    headerAuthData: {
      userTitleUpSell = "",
      zipLabelUpSell = "",
      zipChangeLabelUpSell = "",
    } = {},
    upSellServiceData: {
      presetConfigsUpSell: presetConfigs = "",
      swatchUrlUpSell: swatchUrl = "",
      jsonFilePathUpSell = "",
      isForPostPurchase = false,
    } = {},
    productCard: productCardAuthData = {},
  } = getUpSellAuthData(authData, isRequireToLookupFullData)

  useEffect(() => {
    if (!_isEmpty(cart) && !isForPostPurchase) {
      const { lineItems = [] } = cart
      if (lineItems.length) fetchSkusBasedOnLineItems()
      else setShowUpSellModule(false)
    }
  }, [cart, isAuth])

  useEffect(() => {
    if (isForPostPurchase) {
      processWithPostPurchaseData()
    } else {
      if (isRequireToLookupFullData) {
        setShowUpSellModule(false)
      }
    }
  }, [isForPostPurchase])

  const performZipCodeLookUp = async userZipCodeVal => {
    let consolidatedZips = consolidatedZipCodes
    if (consolidatedZipCodes.length < 1) {
      try {
        const { data: { zipcode = [] } = {} } = await getZipCodes(
          jsonFilePathUpSell
        )
        dispatch(setConsolidatedZipCodes(zipcode))
        consolidatedZips = zipcode
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err, "err in fetching consolidated zip code")
      }
    }
    let availableSkuIdsForService = []
    for (const zipKey of consolidatedZips) {
      const key = Object.keys(zipKey)[0]
      for (const zipInnerKey of zipKey[key]) {
        const zipcode = Object.keys(zipInnerKey)[0]
        if (zipcode === userZipCodeVal) {
          availableSkuIdsForService = zipInnerKey[zipcode]
          break
        }
      }
      if (availableSkuIdsForService.length) break
    }
    return availableSkuIdsForService
  }

  const processWithPostPurchaseData = async () => {
    if (upSellProductData.length) {
      const {
        productItems = [],
        brandName,
        currency,
      } = await getProcessedProductInfoBasedOnLineItems(upSellProductData)

      mapProductDataWithServicesData(productItems, brandName, currency)
    }

    return null
  }

  const fetchSkusBasedOnLineItems = async () => {
    calculateCartData({ cart: sanitizeDOMString(cart) }, true).then(res => {
      const {
        cartItems,
        brandName,
        cart: { price: { currency } = {} } = {},
      } = res
      mapProductDataWithServicesData(cartItems, brandName, currency)
    })
  }

  const mapProductDataWithServicesData = async (
    productItems = [],
    brandName = "",
    currency = ""
  ) => {
    if (!productItems.length) {
      setShowUpSellModule(false)
      return
    }
    const persona = getUserPersona()
    const productCartItems = productItems.filter(
      item => !item?.sku?.includes("INSTALL-SERVICE")
    )
    const installServicesSkus = []
    productCartItems.forEach(item => {
      const { associatedServices = [] } = item
      if (associatedServices.length) {
        installServicesSkus.push(...associatedServices)
      }
    })
    const skuIds = Array.from(new Set(installServicesSkus))
    if (skuIds.length === 0) {
      setShowUpSellModule(false)
      return
    }
    const skusServiceable = await performZipCodeLookUp(zipCodeVal)
    const availableSkusToServe = skusServiceable.filter(value =>
      skuIds.includes(value)
    )
    if (!availableSkusToServe.length) {
      setShowUpSellModule(true)
      setUtilityInfo(false)
      return
    }
    getProductDataFromSkuIds(persona, availableSkusToServe, brandName).then(
      res => {
        if (res && res.data) {
          const rawInstallServiceData = _get(res.data, "response.docs", [])
          const processedInstallServiceData = rawInstallServiceData.map(
            product =>
              getProcessedInstallServiceData(
                product,
                presetConfigs,
                swatchUrl,
                persona,
                currency
              )
          )
          if (!processedInstallServiceData.length) return
          const productAndServiceData = []
          productCartItems.forEach(item => {
            const { associatedServices = [] } = item
            const serviceData =
              processedInstallServiceData.find(
                serviceItem => serviceItem.skuId === associatedServices[0]
              ) ?? {}
            if (!_isEmpty(serviceData)) {
              productAndServiceData.push({
                cartProductData: item,
                installServiceData: {
                  ...serviceData,
                  quantity: item.quantity,
                },
              })
            }
          })
          setShowUpSellModule(productAndServiceData.length)
          setUpSellData(productAndServiceData)
          setUtilityInfo(availableSkusToServe.length ? true : false)
        }
      }
    )
  }

  const handleAddtoCartResponse = ({ payload }) => {
    if (payload) {
      dispatch(setCart(payload))
    }
  }

  const addServiceToCart = async items => {
    const payload = await addToCartItem([...items])
    const body = {
      actions: payload,
    }

    dispatch(addToCart(body))
      .unwrap()
      .then(res => {
        dispatch(showPreviewCart())
        handleAddtoCartResponse(res)
      })
  }

  const handleAddToCart = (item, serviceQuantity) => {
    const {
      installServiceData: { skuId = "", customerBrand = "", quantity = 1 } = {},
    } = item

    addServiceToCart([
      {
        sku: skuId,
        quantity: _isEmpty(serviceQuantity) ? quantity : serviceQuantity[skuId],
        brand: customerBrand,
      },
    ])
  }

  const setUtilityInfo = (showModule = false) => {
    setIsServiceAvailable(showModule)
    setShowInput(false)
    dispatch(setUtilityLocation({ utilityLocation: zipCodeVal }))
    localStorage.setItem("currentZipCode", zipCodeVal)
  }
  const handleZipSerches = (
    type,
    showInput = false,
    isServiceAvailable = false
  ) => {
    if (showInput) setShowInput(showInput)
    else {
      if (!_isEmpty(cart) && !isForPostPurchase) {
        fetchSkusBasedOnLineItems()
      } else processWithPostPurchaseData()
      if (slickRef.current) slickRef.current.slickGoTo(0)
    }
    addAnalyticsForheader(zipCodeVal, type, isServiceAvailable)
  }

  if (showUpSellModule) {
    return (
      <div className={styles.upsellmodal}>
        <div
          className={`upsell-modal ${
            isForPostPurchase && !occupyFullWidth
              ? "upsellmodal-order-history"
              : ""
          }`}
        >
          <div className="upsell-modal-header">
            <div className="upsell-modal-header__title">
              {userTitleUpSell} {firstName}
            </div>
            <div className="upsell-modal-header__rightside">
              <div className="upsell-modal-header__rightside__zipcode-msg">
                {zipLabelUpSell}
              </div>
              <div className="rightside-for-mobile">
                {!showInput ? (
                  <div className="zipcode-if-available">{zipCodeVal}</div>
                ) : (
                  <Input
                    id="zipcode-upsell-modal"
                    type="text"
                    maxLength={5}
                    label={"ZIP Code"}
                    value={zipCodeVal}
                    onChange={e => setZipCodeVal(e.target.value)}
                  />
                )}
                {!showInput ? (
                  <p
                    tabIndex="0"
                    role="link"
                    className="zipcode-change-link"
                    onClick={() =>
                      handleZipSerches(
                        zipChangeLabelUpSell,
                        true,
                        isServiceAvailable
                      )
                    }
                  >
                    {zipChangeLabelUpSell}
                  </p>
                ) : (
                  <div className="zipcode-serach-link">
                    <a role="button" onClick={() => handleZipSerches(search)}>
                      {search}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
          {isServiceAvailable && upSellData.length ? (
            <UpSellInstallServicesCarousalSlick
              upSellData={upSellData}
              authData={getUpSellAuthData(authData, isRequireToLookupFullData)}
              handleAddToCart={handleAddToCart}
              slickRef={slickRef}
            />
          ) : (
            <ProductCardForUpsell authData={productCardAuthData} />
          )}
        </div>
      </div>
    )
  }
  return null
}

export default UpSellInstallServices
