const DisplayDiscountPrice = ({
  discountedPrice = 0,
  price = 0,
  currencySign = "$",
}) => {
  if (discountedPrice && discountedPrice !== price) {
    return (
      <span>
        <s>{`${currencySign} ${price}`}</s>
        {` ${currencySign} ${discountedPrice}`}
      </span>
    )
  }
  return <span>{`${currencySign} ${price}`}</span>
}

export default DisplayDiscountPrice
