import {
  FREE_MSG,
  NAVIGATION,
  ADD_SERVICE,
  TOILET_INSTALL,
  INSTALLATION_SERVICES,
  INSTALL_SERVCIE,
  COMPLIMENTARY,
} from "@/components/Shared/UpsellInstallServices/analytics/constant"
import { SUCCESS } from "@/constants/index"

const getProductInfoForAnalytics = (cartProductData = {}) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const { site: { siteName = "n/a" } = {} } = page
  const brand = siteName.toLowerCase()
  const {
    desc = "",
    discountedPrice = "",
    price = "",
    globalOffer = "n/a",
    globalDiscount = 0,
    name: productTileName = "",
    category = "",
    discountPriceState = "regular price ",
    unitPrice = "n/a",
    color: productColor = "n/a",
    productCoupondiscount = 0,
    productCouponCode = "n/a",
    customerFacingSKU = "n/a",
    productPrice = 0,
    quantity = 0,
    image = "n/a",
  } = cartProductData

  const productInfo = {
    description: desc,
    frequency: "n/a",
    globalPromotionPrice:
      discountedPrice > 0 ? Number((price - 0).toFixed(2)) : 0,
    globalOffer: globalOffer,
    globalDiscount: Number(globalDiscount.toFixed(2)),
    isRecommended: "n/a",
    isSendNow: "n/a",
    isSubscription: "n/a",
    priceState: discountPriceState,
    productBasePrice: Number(unitPrice),
    productCategory: category,
    productColor,
    productCoupondiscount,
    productCouponCode,
    productID: customerFacingSKU,
    productName: productTileName || desc || category || "n/a",
    productPartnerBuyNow: "n/a",
    productRoom: "service" || "n/a",
    productSalePrice: Number(productPrice),
    productSaleable: true,
    productStatus: "in stock",
    productSuperSku: false,
    productTileName,
    quantity,
    defaultImageName: image,
    ratings: "n/a",
    numberOfReviews: "n/a",
    pdpType: "regular finished goods & service parts",
    productFindingMethod: "n/a",
    productCollectionsName: category,
    productBrand: brand,
    itemType: "service" || "n/a",
  }
  return productInfo
}

const addAnalyticsForToolTip = (servicename, title, url, cartProductData) => {
  const services = servicename.toLowerCase()
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageload =
    page.category.subCategoryID === "home"
      ? "homepage"
      : page.category.subCategoryID
  const statusEvent = title == "add to cart" ? SUCCESS : "n/a"
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `upsell products:${ADD_SERVICE}:${title}`,
    eventMsg: "n/a",
    eventName: `${pageload}:${ADD_SERVICE}:${title}`,
    eventStatus: statusEvent,
    eventType: `teaser`,
    internalLinkName: title,
    internalLinkPosition: `${pageload}:teaser`,
    internalLinkType: `${pageload}:${services}`,
    internalLinkURL: url ? url : "n/a",
    internalLinkZoneName: `${pageload}:${ADD_SERVICE}`,
  }
  pushAnalyticsObjToDataLayer(eventInfo, cartProductData)
}
const addAnalyticsForheader = (zipcode, title, isServiceAvailable) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageload =
    page.category.subCategoryID === "home"
      ? "homepage"
      : page.category.subCategoryID
  const titleName = title.toLowerCase()
  const internalLink = isServiceAvailable ? ADD_SERVICE : COMPLIMENTARY
  if (titleName === "change zip code") {
    const eventInfo = {
      clickInternalLinks: "true",
      eventAction: `upsell products:${FREE_MSG}:${titleName}`,
      eventMsg: "n/a",
      eventName: `${pageload}:${FREE_MSG}:${titleName}`,
      eventStatus: "n/a",
      eventType: NAVIGATION,
      internalLinkName: titleName,
      internalLinkPosition: `${pageload}:${NAVIGATION}`,
      internalLinkType: `${pageload}:${zipcode}`,
      internalLinkURL: "n/a",
      internalLinkZoneName: `${pageload}:${internalLink}`,
    }
    pushAnalyticsObjToDataLayer(eventInfo)
  } else {
    const eventInfo = {
      clickInternalLinks: "true",
      eventAction: `upsell products :${INSTALLATION_SERVICES}:${INSTALL_SERVCIE}:check availability`,
      eventMsg: "n/a",
      eventName: `${pageload}:${INSTALL_SERVCIE}:check availability`,
      eventStatus: SUCCESS,
      eventType: NAVIGATION,
      internalLinkName: "search",
      internalLinkPosition: `${pageload}:find ${INSTALLATION_SERVICES}`,
      internalLinkType: `${pageload}:${zipcode}`,
      internalLinkURL: "n/a",
      internalLinkZoneName: `${pageload}:${TOILET_INSTALL}`,
    }
    pushAnalyticsObjToDataLayer(eventInfo)
  }
}

const addAnalyticsForFreeServices = (title, url) => {
  const titleInfo = title.toLowerCase()
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageload =
    page.category.subCategoryID === "home"
      ? "homepage"
      : page.category.subCategoryID
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `upsell products:${FREE_MSG}: ${titleInfo}`,
    eventMsg: "n/a",
    eventName: `${pageload}:${FREE_MSG}:${titleInfo} `,
    eventStatus: "n/a",
    eventType: "teaser",
    internalLinkName: titleInfo,
    internalLinkPosition: `${pageload}:teaser`,
    internalLinkType: `${pageload}:${COMPLIMENTARY}`,
    internalLinkURL: url ? url : "n/a",
    internalLinkZoneName: `${pageload}:${COMPLIMENTARY}`,
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

const addAnalyticsForArrows = (label = "", servicename = "") => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageload =
    page.category.subCategoryID === "home"
      ? "homepage"
      : page.category.subCategoryID
  const type = label == "Next" ? "right" : "left"
  const service = servicename.toLowerCase()
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `upsell products: ${ADD_SERVICE}: ${type} arrow`,
    eventMsg: "n/a",
    eventName: `${pageload}:${ADD_SERVICE}: ${type} arrow`,
    eventStatus: "n/a",
    eventType: "carousel",
    internalLinkName: `${type} arrow`,
    internalLinkPosition: `${pageload}:carousel`,
    internalLinkType: `${pageload}:${service}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${pageload}:${ADD_SERVICE}`,
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

const pushAnalyticsObjToDataLayer = (eventInfo = {}, cartProductData) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const productInfo = cartProductData
    ? getProductInfoForAnalytics(cartProductData)
    : "null"
  const event = "cmp:click"
  window.adobeDataLayer.push({
    event,
    eventInfo,
    page,
    productInfo,
  })
}

export {
  addAnalyticsForToolTip,
  addAnalyticsForheader,
  addAnalyticsForFreeServices,
  addAnalyticsForArrows,
}
