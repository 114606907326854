import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import ProductCard from "@/components/Shared/UpsellInstallServices/ProductCardForUpsell/ProductCard"
import styles from "@/components/Shared/UpsellInstallServices/ProductCardForUpsell/index.module.scss"
import _isEmpty from "lodash/isEmpty"
import { getConfig } from "@/constants/config"
import { getProductDataFromSkuIds } from "@/components/Shared/UpsellInstallServices/api"
import { getUserPersona } from "@/utils/helper"
import { getRowCols } from "@/utils/index"
import { isMobile, isTablet } from "react-device-detect"
import useWindowResize from "@/hooks/useWindowResize"
import { addToCartItem } from "@/utils/addTocart"
import { addToCart, setCart } from "@/store/features/cartSlice"
import { showPreviewCart } from "@/store/features/genericSlice"

const ProductCardForUpsell = ({ authData = {} }) => {
  const {
    skuIds = [],
    presetConfigs = "",
    swatchUrl = "",
    outOfStockTitle = "",
  } = authData
  const [width] = useWindowResize()
  const dispatch = useDispatch()
  const rowCols = getRowCols(width, isMobile, isTablet)
  const persona = getUserPersona()
  const [recommendedProductData, setRecommendedProductData] = useState([])
  const [currency, setCurrencySign] = useState("$")
  const [brand, setBrand] = useState("kohler")
  const callToFetchRecommendedProductData = () => {
    getConfig().then(config => {
      if (!_isEmpty(config)) {
        const {
          general: { siteName = "kohler" } = {},
          internationalization: { currencySign = "$" } = {},
        } = config
        getRecommendedProductData({ siteName, currencySign })
      }
    })
  }

  useEffect(() => {
    if (persona) callToFetchRecommendedProductData()
  }, [persona])
  const getRecommendedProductData = ({
    siteName = "kohler",
    currencySign = "$",
  }) => {
    getProductDataFromSkuIds(persona, skuIds, siteName).then(res => {
      if (!_isEmpty(res)) {
        const { data: { response: { docs = [] } = {} } = {} } = res
        setCurrencySign(currencySign)
        setBrand(siteName)
        setRecommendedProductData(docs)
      }
    })
  }
  const handleAddToCart = async (items = [], cb = () => {}) => {
    const payload = await addToCartItem([...items])
    const body = {
      actions: payload,
    }

    dispatch(addToCart(body))
      .unwrap()
      .then(res => {
        dispatch(showPreviewCart())
        const { payload = {} } = res
        if (!_isEmpty(payload)) {
          dispatch(setCart(payload))
        }
        cb()
      })
      .catch(err => {
        cb()
      })
  }
  return (
    <div className={styles.curatedProduct}>
      <div className="curated-product-recommendation">
        {recommendedProductData.map(item => {
          return (
            <React.Fragment key={item.id}>
              <ProductCard
                addToCart={handleAddToCart}
                {...item}
                persona={persona}
                currencySign={currency}
                brand={brand}
                presetConfigs={presetConfigs}
                rowCols={rowCols}
                swatchUrl={swatchUrl}
                outOfStockTitle={outOfStockTitle}
              />
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default ProductCardForUpsell
