import { memo, useEffect, useState } from "react"
import Button from "@/components/core/Button/Button"
import styles from "@/components/Shared/UpsellInstallServices/ProductCardForUpsell/index.module.scss"
import useWindowResize from "@/hooks/useWindowResize"
import { getPresetUrl } from "@/utils/index"
import { getStock } from "@/utils/cart"
import { getPDPUrl } from "@/utils/helper"

const ProductCard = ({
  persona = "GST",
  currencySign = "$",
  brand = "kohler",
  presetConfigs = "",
  rowCols = 0,
  swatchUrl: baseUrl = "",
  outOfStockTitle = "",
  addToCart = () => {},
  ...item
}) => {
  const [width] = useWindowResize()
  const [isProductAvailable, setIsProductAvailable] = useState(true)
  const [pdpUrl, setPdpUrl] = useState("")
  const [loading, setLoading] = useState(false)
  const {
    sku_s: skuId = "",
    ProductBrandName_s: name = "",
    productName_s: productName = "",
    [`priceList.${persona}.price_d`]: productPrice = 0,
    [`priceList.${persona}.discountedPrice_d`]: discountedPrice = 0,
    ["productImages.labelWithUrl_ss"]: labledImages = [],
    Product_Category: productCategory = "",
    slug_s: slug = "",
  } = item

  useEffect(() => {
    getPDPUrl(productCategory, slug).then(url => setPdpUrl(url))
    checkForProductQuantity()
  }, [])

  const checkForProductQuantity = async () => {
    const isInStock = await getStock(skuId)
    setIsProductAvailable(isInStock ? true : false)
  }

  const price = Number(productPrice).toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const productPresetConfigs = presetConfigs ? JSON.parse(presetConfigs) : {}
  let imgId = ""
  if (labledImages.length) {
    const index = labledImages
      .map(item => (item.split("|")?.length ? item.split("|")[0] : ""))
      .indexOf(skuId)
    const assetId = labledImages[index]?.split("|")?.length
      ? labledImages[index].split("|")[1]
      : ""
    imgId = assetId
  }

  const handleAddToCartCb = () => {
    setLoading(false)
  }

  const handleAddToCart = e => {
    e.preventDefault()
    setLoading(true)
    const payload = [
      {
        sku: skuId,
        quantity: 1,
        brand: brand?.toLowerCase(),
      },
    ]
    addToCart(payload, handleAddToCartCb)
  }
  const productImageUrl = getPresetUrl(
    width,
    rowCols,
    baseUrl,
    imgId,
    productPresetConfigs
  )
  return (
    <div className={styles.curatedProduct}>
      <div className="curated-product-recommendation__card-list">
        <a href={`${pdpUrl}${skuId ? "?skuId=" + skuId : ""}`}>
          <div className="curated-product-recommendation__product-card">
            <div className="curated-product-recommendation__img">
              <img src={productImageUrl} alt={name} />
            </div>
            <div className="curated-product-recommendation__product-name">
              {name || productName}
            </div>
            <div className="curated-product-recommendation__product-sku">
              {skuId}
            </div>
            {discountedPrice > 0 ? (
              <div className="curated-product-recommendation__discounted-price">
                <div className="price-with-discount">{`${currencySign}${discountedPrice}`}</div>
                <div className="original-price">{`${currencySign}${price}`}</div>
              </div>
            ) : (
              <div className="curated-product-recommendation__product-price">
                {`${currencySign}${price}`}
              </div>
            )}
            {!isProductAvailable ? (
              <div className="curated-product-recommendation__out-of-stock">
                {outOfStockTitle}
              </div>
            ) : (
              <div className="curated-product-recommendation__add-to-cart">
                <Button
                  role="button"
                  label={"Add To Cart"}
                  size="small"
                  type="primary"
                  flexible={true}
                  onClick={handleAddToCart}
                  loading={loading}
                />
              </div>
            )}
          </div>
        </a>
      </div>
    </div>
  )
}
export default memo(ProductCard)
