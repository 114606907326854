import React, { useState } from "react"
import Slider from "react-slick"
import Button from "@/components/core/Button/Button"
import QuantitySelector from "@/components/cart/QuantitySelector"
import useWindowResize from "@/hooks/useWindowResize"
import htmlParser from "html-react-parser"
import { getPresetUrl, getRowCols } from "@/utils/index"
import { isMobile, isTablet } from "react-device-detect"
import ToolTip from "@/components/core/ToolTip/ToolTip"
import _isEmpty from "lodash/isEmpty"
import { singleSliderSettings } from "@/components/Default/CarouselSlick/v1/SlickSettings"
import {
  addAnalyticsForToolTip,
  addAnalyticsForArrows,
} from "@/components/Shared/UpsellInstallServices/analytics"
import DisplayDiscountPrice from "@/components/Shared/DisplayDiscountPrice"

const UpSellInstallServicesCarousalSlick = ({
  upSellData,
  authData,
  handleAddToCart,
  slickRef,
}) => {
  const {
    upSellServiceData: {
      productTitleUpSell = "",
      serviceAvailableTitleUpSell = "",
      serviceAvailableDescUpsell = "",
      toolTipsUpSell = "",
      learnAboutUpSell = "",
      priceGuardUpSell = "",
      toolTipsDescUpSell = "",
      presetConfigsUpSellProduct: productPresetConfigs = {},
    } = {},
  } = authData

  const [width] = useWindowResize()
  const [serviceQuantity, setServiceQuantity] = useState({})
  const [tooltip, setTooltip] = useState({ buyMoreSaveMore: false })
  const rowCols = getRowCols(width, isMobile, isTablet)
  let arrowLabel = "Next"
  const slickSettings = singleSliderSettings(
    (_, current) => {
      const index =
        arrowLabel === "Next" ? Number(current) - 1 : Number(current) + 1
      const currentSlideServiceData = upSellData[index]
      if (!_isEmpty(currentSlideServiceData)) {
        const { installServiceData: { name = "" } = {} } =
          currentSlideServiceData
        addAnalyticsForArrows(arrowLabel, name)
      }
    },
    0,
    false,
    500,
    label => {
      arrowLabel = label
    }
  )
  slickSettings["infinite"] = false

  const handleChange = (qty, item) => {
    const { installServiceData: { skuId = "" } = {} } = item
    const quantity = serviceQuantity
    quantity[skuId] = qty
    setServiceQuantity(quantity)
  }
  const handleFieldInfoMouseOver = (
    tooltipFor = "buyMoreSaveMore",
    value = false
  ) => {
    setTooltip({ ...tooltip, [tooltipFor]: value })
  }
  const addTocarthandler = (
    item,
    serviceQuantity,
    serviceName,
    title,
    cartProductData
  ) => {
    handleAddToCart(item, serviceQuantity)
    addAnalyticsForToolTip(serviceName, title, "", cartProductData)
  }
  const installserviceClickHandler = (e, serviceName) => {
    const { target: { href = "", textContent = "" } = {} } = e
    addAnalyticsForToolTip(serviceName, textContent.toLowerCase(), href)
  }
  const priceGuardClickHandler = (e, serviceName) => {
    const { target: { href = "", textContent = "" } = {} } = e
    addAnalyticsForToolTip(serviceName, textContent.toLowerCase(), href)
  }
  return (
    <Slider key="slider-upsell" ref={slickRef} {...slickSettings}>
      {upSellData.map(item => {
        const {
          cartProductData = {},
          cartProductData: {
            name = "",
            desc = "",
            quantity = 1,
            sku = "",
            customerFacingSKU = "",
            unitPrice = 0,
            currencySign = "$",
            image = "",
            associatedServices = [],
          } = {},
          installServiceData: {
            quantity: installQuantity,
            name: serviceName = "",
            description = "",
            price = 0,
            discountedPrice = 0,
            images = "",
            presetConfigs: imgConfigs = {},
            swatchUrl: baseUrl,
          } = {},
        } = item
        if (!associatedServices.length) return null
        const imgUrl = getPresetUrl(
          width,
          rowCols,
          baseUrl,
          images,
          imgConfigs,
          true
        )
        const imgId = image?.split("/").at(-1)
        const parsedConfigs = !_isEmpty(productPresetConfigs)
          ? JSON.parse(productPresetConfigs)
          : {}
        const productImageUrl = getPresetUrl(
          width,
          rowCols,
          baseUrl,
          imgId,
          parsedConfigs,
          true
        )
        return (
          <React.Fragment key={sku}>
            <div className="upsell-modal__container">
              <div className="left-side">
                <div className="head">{productTitleUpSell}</div>

                <div className="left-side--moblie">
                  <div className="image">
                    <img src={productImageUrl} alt={name} />
                  </div>
                  <div className="prouct-info--mobile">
                    <div className="product-name">{name}</div>
                    <div className="product-description">{desc}</div>
                    <div className="product-sku">{customerFacingSKU}</div>
                    <div className="product-price">{`${currencySign} ${unitPrice}`}</div>
                    <div className="product-qty">Qty: {quantity}</div>
                  </div>
                </div>
              </div>
              <div className="right-side">
                <div className="title">
                  {serviceAvailableTitleUpSell
                    ? htmlParser(serviceAvailableTitleUpSell)
                    : null}
                </div>
                <div className="description">{serviceAvailableDescUpsell}</div>
                <div className="installation-services">
                  <div className="installation-services__left">
                    <div className="installation-services__image">
                      <img src={imgUrl} alt={serviceName} />
                    </div>
                    <div className="installation-services__links">
                      <div
                        className="installation-services__learn-more"
                        role="link"
                        onClick={e =>
                          installserviceClickHandler(e, serviceName)
                        }
                      >
                        {learnAboutUpSell ? htmlParser(learnAboutUpSell) : null}
                      </div>
                      <div
                        className="installation-services__price-guard"
                        role="link"
                        onClick={e => priceGuardClickHandler(e, serviceName)}
                      >
                        {priceGuardUpSell ? htmlParser(priceGuardUpSell) : null}
                      </div>
                    </div>
                  </div>
                  <div className="installation-services__right">
                    <div className="installation-services__right--mobile">
                      <div className="installation-services__title">
                        {serviceName}
                      </div>
                      <div className="installation-services__price--moblie">
                        {`${currencySign}${price}`}
                      </div>
                    </div>
                    <div className="installation-services__description">
                      {description}
                    </div>
                    <div className="installation-services__price">
                      <DisplayDiscountPrice
                        discountedPrice={discountedPrice}
                        price={price}
                        currencySign={currencySign}
                      />
                    </div>
                    <div className="installation-services__add-to-cart">
                      <div className="installation-services__quantity-selector">
                        <QuantitySelector
                          size="small"
                          value={installQuantity}
                          source="cart-landing"
                          onChange={qty => handleChange(qty, item)}
                        />
                      </div>
                      <div className="add-to-cart">
                        <Button
                          role="button"
                          label={"Add To Cart"}
                          onClick={() =>
                            addTocarthandler(
                              item,
                              serviceQuantity,
                              serviceName,
                              "add to cart",
                              cartProductData
                            )
                          }
                          size="large"
                          type="primary"
                          flexible={true}
                        />
                      </div>
                    </div>
                    <div
                      className="installation-services__tool-tip"
                      onMouseOver={() => {
                        handleFieldInfoMouseOver("buyMoreSaveMore", true)
                        addAnalyticsForToolTip(
                          serviceName,
                          "buy more, save more",
                          "",
                          cartProductData
                        )
                      }}
                      onFocus={() => {
                        handleFieldInfoMouseOver("buyMoreSaveMore", true)
                        addAnalyticsForToolTip(
                          serviceName,
                          "buy more, save more",
                          "",
                          cartProductData
                        )
                      }}
                      onMouseLeave={() =>
                        handleFieldInfoMouseOver("buyMoreSaveMore", false)
                      }
                      onBlur={() =>
                        handleFieldInfoMouseOver("buyMoreSaveMore", false)
                      }
                    >
                      <div
                        className={`installation-services__tool-tip ${
                          tooltip.buyMoreSaveMore ? "--show" : "--hide"
                        }`}
                      >
                        <div className="installation-services__tool-tip-wrapper">
                          <ToolTip message={toolTipsDescUpSell}></ToolTip>
                        </div>
                      </div>
                      {toolTipsUpSell}
                    </div>
                    <div className="installation-services__links--mobile">
                      <div
                        className="installation-services__learn-more"
                        role="link"
                        onClick={e =>
                          installserviceClickHandler(e, serviceName)
                        }
                      >
                        {learnAboutUpSell ? htmlParser(learnAboutUpSell) : null}
                      </div>
                      <div
                        className="installation-services__price-guard"
                        role="link"
                        onClick={e => priceGuardClickHandler(e, serviceName)}
                      >
                        {priceGuardUpSell ? htmlParser(priceGuardUpSell) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      })}
    </Slider>
  )
}

export default UpSellInstallServicesCarousalSlick
