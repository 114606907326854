/**
 * It add to cart Utility for pay load
 * @returns the addToCartItem
 */

import { getConfig } from "@/constants/config"
export const addToCartItem = async items => {
  let userZipCode
  const CONFIG = await getConfig()
  const salesChannels = CONFIG?.salesChannelConfig?.map ?? {}
  const actionPayload = actionProduct(items, salesChannels, userZipCode)
  return actionPayload
}
/**
 *  payload to add to cart.
 * @param {items} items The first param.
 * @param {salesChannels} salesChannels The second param.
 * @param {userZipCode} userZipCode The third param.
 * @return {object} the pay load for add to cart
 */
const actionProduct = (items, salesChannels, userZipCode) => {
  const actions = []
  let actionData
  items.forEach(product => {
    if (product.backorder) fields.isBackOrderable = true
    actions.push(
      {
      action: product.isBundle ? "addbundle" : "addLineItem",
      sku: product.sku,
      quantity: product.quantity ?? 0,
      custom: product?. isInstallService ? {
        type: {
          key: "cartLineItemAdditionalInfo",
          typeId: "type"
      },
      fields: {
          associatedService: product?.installServiceName,
          associatedServiceQty: product?.installServiceQty || 1,
          warehouses : product?.warehouses 
      }
      }
      : !product?.sku?.split('_').includes('INST') ?
      {
        type: {
          key: "cartLineItemAdditionalInfo",
          typeId: "type"
      },
      fields: {
          warehouses : product?.warehouses 
      }
      } : ""
    }
    )
    userZipCode = product?.userZipCode
    // console.log(actions)
    actionData = [...actions]
  })
  return actionData
}
